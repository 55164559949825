import "./featuredInfo.css";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

export default function FeaturedInfo() {
  return (
    <div className="featured">

      <div className="featuredItem">
        <span className="featuredTitle">ASSETS UNDER MANAGEMENT (USD) </span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">$90,150</span>
    {
      /**
       * CHECK: 9 buyback,0.6 market,2 liq
       *           <span className="featuredMoneyRate">
            -11.4 <ArrowDownward  className="featuredIcon negative"/>
          </span>
       */
    }
        </div>        
      </div>






      <div className="featuredItem">
        <span className="featuredTitle">ASSETS UNDER MANAGEMENT (BNB)</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">185 BNB</span>
          
        </div>
        {
          /**
           * <span className="featuredSub">Compared to last month</span>
           */
        }
      </div>
    </div>
  );
}