import "./widgetLg.css";

export default function WidgetLg() {
  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };
  return (
    <div className="widgetLg">
      <table className="widgetLgTable">

        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">Marketcap</span>
          </td>
          <td className="widgetLgDate">(V2 PENDING)</td>
        </tr>


        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">ATH</span>
          </td>
          <td className="widgetLgDate">(V2 PENDING)</td>
        </tr>


        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">PRICE</span>
          </td>
          <td className="widgetLgDate">(V2 PENDING)</td>
        </tr>

        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <span className="widgetLgName">ATH</span>
          </td>
          <td className="widgetLgDate">(V2 PENDING)</td>
        </tr>



      </table>
    </div>
  );
}