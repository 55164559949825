import "./widgetSm.css";
import { Visibility } from "@material-ui/icons";
export default function WidgetSm() {
  return (
    <div className="widgetSm">
      <span className="widgetSmTitle">ASSETS</span>
      <ul className="widgetSmList">
        <li className="widgetSmListItem">
          <div className="widgetSmUser">
            <span className="widgetSmUsername">HARMONY - $26,207</span>            
          </div>
        </li>
        <li className="widgetSmListItem">
          <div className="widgetSmUser">
            <span className="widgetSmUsername">AVAX - $33,141</span>            
          </div>
        </li>

        <li className="widgetSmListItem">
          <div className="widgetSmUser">
            <span className="widgetSmUsername">FTM - $27,100</span>            
          </div>
        </li>

        <li className="widgetSmListItem">
          <div className="widgetSmUser">
            <span className="widgetSmUsername">BNB - $1,200</span>            
          </div>
        </li>

        <li className="widgetSmListItem">
          <div className="widgetSmUser">
            <span className="widgetSmUsername">NFTS - $2,500</span>            
          </div>
        </li>

 
      </ul>
    </div>
  );
}